import React from 'react'
import { Link } from 'gatsby'
import styles from './landing.module.scss'
import logo from '../../assets/uthrulogo_pinkBG.png'

const HeaderLinks = props => {
  return (
    <Link to={props.to} className={`${styles.btn_primary} btn`}>
      {props.text}
    </Link>
  )
}
// LOGO component
const Logo = props => (
  <Link to={props.to}>
    <img src={logo} alt="Logo" />
  </Link>
)

const NavMenu2 = () => (
  <>
    <HeaderLinks to="/agents" text="I’m an agent" />
    <HeaderLinks to="/buyersrenters" text="I’m a buyer/renter" />
  </>
)

const Landing = props => (
  <div className={`${styles.banner} row`}>
    <div className={`${styles.overlay} col`} />
    <header className="col">
      <div className={`${styles.banner_row} row`}>
        <nav>
          <div className={styles.logo}>
            <Logo to="/" />
          </div>
        </nav>
      </div>
      <div className={styles.headerSectionContents} id="top">
        <h2 className={styles.spantitle}>
          The <span className={styles.spanFuture}>future</span> of property inspections
        </h2>
        <p className={styles.subcontents}>{props.content}</p>
        <div className={styles.navigationbuttons}>
          <NavMenu2 />
        </div>
      </div>
    </header>
  </div>
)
export default Landing
