import React from 'react'
import styles from '../layout/layout.module.scss'
import logo from '../../assets/uthrulogo_purpleBG.png'
import { Link } from 'gatsby'
import { DISCLAIMER, PRIVACY, TERMS } from '../../routes'

const Footer = () => (
  <div className={`${styles.footer} row`}>
    <div className="col-lg-4 text-center text-lg-left">
      <p className={styles.footer_contact}>For more information, please contact:</p>
      <p className={styles.footer_mail}>
        <a href="mailto:hello@uthru.io" className={styles.footer_mail}>
          hello@uthru.io
        </a>
      </p>
      <p>
        <a href="#top" className={styles.scrolltop}>
          Back to top
        </a>
      </p>
    </div>
    <div className="col-lg-8 text-center text-lg-right">
      <p className={styles.footer_logo}>
        <img src={logo} alt="Logo" width={65} />
      </p>
      <ul className={`${styles.footer_navigation} list-unstyled`}>
        <li>
          <Link to={DISCLAIMER}>Disclaimer</Link>
        </li>
        <li>
          <Link to={TERMS}>Terms of Service</Link>
        </li>
        <li>
          <Link to={PRIVACY}>Privacy Policy</Link>
        </li>
        <li>© Copyright 2020 U Thru Pty Limited</li>
      </ul>
    </div>
  </div>
)
export default Footer
